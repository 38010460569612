import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './style.scss'

import { Provider } from "react-redux";
import Store from "./reducer";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
    <App />
  </Provider>
);
