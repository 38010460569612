// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsive-wrapper {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}`, "",{"version":3,"sources":["webpack://./src/app/ResponsiveWrapper/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,sBAAA;EACA,4BAAA;AACF","sourcesContent":[".responsive-wrapper {\n  width: 100%;\n  height: 100%;\n  background-color: #ffffff;\n  position: relative;\n  background-size: cover;\n  background-repeat: no-repeat;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
