// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  font-size: 0.875rem;
  min-height: 265px;
  border-top: 1rem solid var(--primary-blue);
}
.footer-container .container {
  padding: 40px 10px;
}
@media screen and (min-width: 992px) {
  .footer-container .container {
    padding: 40px 80px;
  }
}
.footer-container a,
.footer-container a span {
  color: black;
  text-decoration: none;
}
.footer-container h5 {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 600;
}
.footer-container .footer-link {
  transition: 0.3s all;
}
.footer-container .footer-link:hover {
  color: var(--bs-orange);
  text-decoration: underline !important;
}
.footer-container .footer-map-col {
  gap: 24px;
}
.footer-container .want-to-reach-btn {
  padding: 1rem 2.5rem;
  border-radius: 60px;
  border: 1px solid black;
  font-weight: 800;
  margin-top: 40px;
}
.footer-container .footer-bottom {
  border-top: 1px solid var(--grey-light);
  padding: 20px;
  font-size: 12px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/style.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,iBAAA;EACA,0CAAA;AACF;AACE;EACE,kBAAA;AACJ;AACI;EAHF;IAII,kBAAA;EAEJ;AACF;AACE;;EAEE,YAAA;EACA,qBAAA;AACJ;AAEE;EACE,SAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,oBAAA;AADJ;AAEI;EACE,uBAAA;EACA,qCAAA;AAAN;AAIE;EACE,SAAA;AAFJ;AAKE;EACE,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;AAHJ;AAME;EACE,uCAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;AAJJ","sourcesContent":[".footer-container {\n  font-size: 0.875rem;\n  min-height: 265px;\n  border-top: 1rem solid var(--primary-blue);\n\n  .container {\n    padding: 40px 10px;\n\n    @media screen and (min-width: 992px) {\n      padding: 40px 80px;\n    }\n  }\n\n  a,\n  a span {\n    color: black;\n    text-decoration: none;\n  }\n\n  h5 {\n    margin: 0;\n    font-size: 0.875rem;\n    font-weight: 600;\n  }\n\n  .footer-link {\n    transition: 0.3s all;\n    &:hover {\n      color: var(--bs-orange);\n      text-decoration: underline !important;\n    }\n  }\n\n  .footer-map-col {\n    gap: 24px;\n  }\n\n  .want-to-reach-btn {\n    padding: 1rem 2.5rem;\n    border-radius: 60px;\n    border: 1px solid black;\n    font-weight: 800;\n    margin-top: 40px;\n  }\n\n  .footer-bottom {\n    border-top: 1px solid var(--grey-light);\n    padding: 20px;\n    font-size: 12px;\n    font-weight: 500;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
