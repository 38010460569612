// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation {
  gap: 1rem;
  right: 40px;
}
.navigation .card-prev-btn {
  width: 40px;
  height: 40px;
  z-index: 2;
  color: var(--bs-orange);
}
.navigation .card-prev-btn:disabled {
  color: black;
  opacity: 0.5;
}
.navigation .card-next-btn {
  width: 40px;
  height: 40px;
  z-index: 2;
  color: var(--bs-orange);
}
.navigation .card-next-btn:disabled {
  color: black;
  opacity: 0.5;
}
@media screen and (max-width: 786px) {
  .navigation {
    width: 100%;
    justify-content: space-between;
    right: 0 !important;
    padding: 0 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/SwiperNavigation/style.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,WAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,UAAA;EACA,uBAAA;AACJ;AACI;EACE,YAAA;EACA,YAAA;AACN;AAGE;EACE,WAAA;EACA,YAAA;EACA,UAAA;EACA,uBAAA;AADJ;AAGI;EACE,YAAA;EACA,YAAA;AADN;AAKE;EA5BF;IA6BI,WAAA;IACA,8BAAA;IACA,mBAAA;IACA,eAAA;EAFF;AACF","sourcesContent":[".navigation {\n  gap: 1rem;\n  right: 40px;\n\n  .card-prev-btn {\n    width: 40px;\n    height: 40px;\n    z-index: 2;\n    color: var(--bs-orange);\n\n    &:disabled {\n      color: black;\n      opacity: 0.5;\n    }\n  }\n\n  .card-next-btn {\n    width: 40px;\n    height: 40px;\n    z-index: 2;\n    color: var(--bs-orange);\n\n    &:disabled {\n      color: black;\n      opacity: 0.5;\n    }\n  }\n\n  @media screen and (max-width: 786px) {\n    width: 100%;\n    justify-content: space-between;\n    right: 0 !important;\n    padding: 0 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
