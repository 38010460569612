// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-popup-wrapper {
  z-index: 1000;
}
.content-popup-wrapper img {
  aspect-ratio: 1;
  border-radius: 20px;
}
.content-popup-wrapper .swiper-wrapper {
  padding-bottom: 40px;
}
.content-popup-wrapper .content-popup-container {
  z-index: 1001;
  max-width: 1100px;
  box-shadow: 0px 4px 50px 48px rgba(0, 0, 0, 0.2509803922);
  padding: 40px;
  gap: 40px;
  border-radius: 20px;
}
.content-popup-wrapper .content-popup-container button {
  right: 10px;
  top: 10px;
  color: black;
}
@media screen and (min-width: 786px) {
  .content-popup-wrapper .content-popup-container button {
    right: -20px;
    top: -30px;
    color: white;
  }
}
@media screen and (max-width: 786px) {
  .content-popup-wrapper .swiper-slide {
    width: 100% !important;
  }
  .content-popup-wrapper .content-popup-container {
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: auto;
    border-radius: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Popups/ContentPopup/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,eAAA;EACA,mBAAA;AACJ;AAEE;EACE,oBAAA;AAAJ;AAGE;EACE,aAAA;EACA,iBAAA;EACA,yDAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;AADJ;AAGI;EACE,WAAA;EACA,SAAA;EACA,YAAA;AADN;AAGM;EALF;IAMI,YAAA;IACA,UAAA;IACA,YAAA;EAAN;AACF;AAIE;EACE;IACE,sBAAA;EAFJ;EAIE;IACE,sBAAA;IACA,mBAAA;IACA,YAAA;IACA,cAAA;IACA,gBAAA;EAFJ;AACF","sourcesContent":[".content-popup-wrapper {\n  z-index: 1000;\n\n  img {\n    aspect-ratio: 1;\n    border-radius: 20px;\n  }\n\n  .swiper-wrapper {\n    padding-bottom: 40px;\n  }\n\n  .content-popup-container {\n    z-index: 1001;\n    max-width: 1100px;\n    box-shadow: 0px 4px 50px 48px #00000040;\n    padding: 40px;\n    gap: 40px;\n    border-radius: 20px;\n\n    button {\n      right: 10px;\n      top: 10px;\n      color: black;\n\n      @media screen and (min-width: 786px) {\n        right: -20px;\n        top: -30px;\n        color: white;\n      }\n    }\n  }\n\n  @media screen and (max-width: 786px) {\n    .swiper-slide {\n      width: 100% !important;\n    }\n    .content-popup-container {\n      flex-direction: column;\n      align-items: center;\n      height: 100%;\n      overflow: auto;\n      border-radius: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
