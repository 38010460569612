export const setIsLoading = (data) => {
  return { type: "SET_IS_LOADING", payload: data };
};

export const setIsLogin = (data) => {
  return { type: "SET_IS_LOGIN", payload: data };
};

export const setIsOverlay = (data) => {
  return { type: "SET_IS_OVERLAY", payload: data };
};

export const setIsOverflow = (data) => {
  return { type: "SET_IS_OVERFLOW", payload: data };
};

export const setSelectedNavAction = (data) => {
  return { type: "SET_SELECTED_NAV_ACTION", payload: data };
};

export const setDimension = (data) => {
  return { type: "SET_DIMENSION", payload: data };
};

export const setIsLoginPopupOpened = (data) => {
  return { type: "SET_IS_LOGIN_POPUP_OPENED", payload: data };
};

export const setSnakeBarContent = (data) => {
  return { type: "SET_SNAKE_BAR_CONTENT", payload: data };
};

export const setSecondDrawerOpen = (data) => {
  return { type: "SET_SECOND_DRAWER_OPEN", payload: data };
};

export const setServices = (data) => {
  return { type: "SET_SERVICES", payload: data };
};

export const setUserProfile = (data) => {
  return { type: "SET_USER_PROFILE", payload: data };
};

export const setUserAddresses = (data) => {
  return { type: "SET_USER_ADDRESSES", payload: data };
};

export const setTopProfessionals = (data) => {
  return { type: "SET_TOP_PROFESSIONALS", payload: data };
};
