import React from "react";
import "./style.scss";

export default function ChatButton() {
  return (
    <div className="chat-button-wrapper">
      <a
        href="https://api.whatsapp.com/send/?phone=919148069148&text=Hi&type=phone_number&app_absent=0"
        target="_blank"
        className="px-3 py-2 d-flex align-items-center"
      >
        <p>Chat with Us</p>

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M25.9208 6.06902C23.2886 3.44636 19.788 2.00156 16.0586 2C8.37408 2 2.12004 8.22378 2.11692 15.8737C2.11598 18.319 2.75776 20.7059 3.97787 22.8102L2 30L9.39068 28.0705C11.427 29.1759 13.7197 29.7586 16.053 29.7592H16.0586C16.0583 29.7592 16.0589 29.7592 16.0586 29.7592C23.7422 29.7592 29.9969 23.5348 30 15.8846C30.0016 12.1774 28.5529 8.69169 25.9208 6.06902ZM10.397 16.3786C10.2228 16.1472 8.9746 14.4989 8.9746 12.7924C8.9746 11.086 9.8746 10.2472 10.1938 9.90036C10.5129 9.55347 10.8906 9.46667 11.1228 9.46667C11.3551 9.46667 11.5877 9.46884 11.7906 9.4788C12.0044 9.48969 12.2917 9.39791 12.5743 10.074C12.8647 10.768 13.5612 12.4748 13.6484 12.6481C13.7356 12.8217 13.7935 13.0239 13.6775 13.2554C13.5615 13.4865 13.5034 13.6312 13.3292 13.8337C13.1551 14.0363 12.9635 14.2858 12.8066 14.441C12.6321 14.614 12.4505 14.8016 12.6537 15.1485C12.8569 15.4957 13.5559 16.6309 14.5915 17.5503C15.922 18.7316 17.0446 19.0974 17.3928 19.271C17.7411 19.4446 17.9443 19.4157 18.1475 19.1842C18.3507 18.9528 19.0184 18.1719 19.2507 17.825C19.4829 17.4781 19.7152 17.536 20.0344 17.6514C20.3538 17.7671 22.0663 18.6059 22.4146 18.7792C22.7628 18.9528 22.9951 19.0396 23.0823 19.1842C23.1695 19.3289 23.1695 20.023 22.8791 20.8328C22.5887 21.6426 21.197 22.3818 20.5277 22.4811C19.9275 22.5704 19.1681 22.6077 18.3335 22.3436C17.8277 22.184 17.1787 21.9705 16.3475 21.6134C12.8534 20.1116 10.5714 16.6101 10.397 16.3786Z" fill="black"></path></svg>
      </a>
    </div>
  );
}
