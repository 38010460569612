// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-button-wrapper {
  position: fixed;
  right: -60px;
  z-index: 1000;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}
.chat-button-wrapper a {
  background: rgb(248, 204, 53);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  gap: 20px;
  text-decoration: none;
}
.chat-button-wrapper a p {
  color: black;
}
.chat-button-wrapper a svg {
  transform: rotate(90deg);
}`, "",{"version":3,"sources":["webpack://./src/components/ChatButton/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,aAAA;EACA,QAAA;EACA,0CAAA;AACF;AACE;EACE,6BAAA;EACA,4BAAA;EACA,6BAAA;EACA,SAAA;EACA,qBAAA;AACJ;AACI;EACE,YAAA;AACN;AAEI;EACE,wBAAA;AAAN","sourcesContent":[".chat-button-wrapper {\n  position: fixed;\n  right: -60px;\n  z-index: 1000;\n  top: 50%;\n  transform: translateY(-50%) rotate(-90deg);\n\n  a {\n    background: rgba(248, 204, 53, 1);\n    border-top-left-radius: 20px;\n    border-top-right-radius: 20px;\n    gap: 20px;\n    text-decoration: none;\n\n    p {\n      color: black;\n    }\n\n    svg {\n      transform: rotate(90deg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
